/* Remove Navbar flexbox classes on mobile */
jQuery(document).ready(function ($) {
    var alterClass = function () {
        var ww = document.body.clientWidth;
        if (ww < 991) {
            $('#jsmobilefix').removeClass('d-flex justify-content-end w-100');
        } else if (ww >= 991) {
            $('#jsmobilefix').addClass('d-flex justify-content-end w-100');
        };
    };
    $(window).resize(function () {
        alterClass();
    });
    //Fire it when the page first loads:
    alterClass();
});

/* Enable tooltips everywhere */
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
})


// ===== viewport function ==== 
$.fn.isInViewport = function () {
    var elementTop = $(this).offset().top;
    var elementBottom = elementTop + $(this).outerHeight();

    var viewportTop = $(window).scrollTop();
    var viewportBottom = viewportTop + $(window).height();

    return elementBottom > viewportTop && elementTop < viewportBottom;
};

// ===== lottie function to play animantions when in viewport ==== 
function lottieStopAndPlay(anchor) {
    var check = true;
    var myElem = document.getElementById(anchor);
    if (myElem) {
        $(window).on('resize scroll', function () {
            if ($('#'.anchor).isInViewport() && check == true) {
                bodymovin.stop(anchor);
                bodymovin.play(anchor);
                check = false;
            }
        });
    }
}


// ===== Lottie ==== 
var animations = ['ado-fremdveranstaltung', 'ado-inhouse', 'ado-kontakt', 'ado-lehrgang-betriebshof', 'ado-nachhaltigkeit', 'ado-qualifizierung-wertstoffhof', 'ado-veranstaltungen', 'ado-weiterbildung'];
var len = animations.length;
for (i = 0; i < len; i += 1) {
    var anim;
    var elem = document.getElementById(animations[i])
    var pathJson = '/json/' + animations[i] + '.json';
    var animData = {
        container: elem,
        renderer: 'svg',
        loop: false,
        autoplay: true,
        name: animations[i],
        path: pathJson
    };
    if (elem) {
        anim = bodymovin.loadAnimation(animData);
        anim.setSubframe(false);
    }
}
$(window).on('resize scroll', function () {
    for (i = 0; i < len; i += 1) {
        if ($("#" + animations[i]).isInViewport() && !$("#" + animations[i]).hasClass("played")) {
            bodymovin.stop(animations[i]);
            bodymovin.play(animations[i]);
            $("#" + animations[i]).addClass("played");
        }
    }
});

// ===== Scroll to Top ==== 
$(window).scroll(function () {
    if ($(this).scrollTop() >= 120) { // If page is scrolled more than 50px
        $('#return-to-top').fadeIn(200); // Fade in the arrow
    } else {
        $('#return-to-top').fadeOut(200); // Else fade out the arrow
    }
});
$('#return-to-top').click(function () { // When arrow is clicked
    $('body,html').animate({
        scrollTop: 0 // Scroll to top of body
    }, 500);
});

// ===== Smooth Scroll for anchor links ==== 
$(function() {
    $('a[href*=#]:not([href=#])').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({ scrollTop: target.offset().top -50 }, 500);
          return false;
        }
      }
    });
  });

// ===== Fix for is-focused class  ==== 
jQuery(document).ready(function ($) {

    $(".form-control").focus(function () {
        $(this).parent().addClass("is-focused");

    }).blur(function () {
        $(this).parent().removeClass("is-focused");
    })

});

// ===== Fix for is-filled class  ====   
$('.form-control').blur(function () {
    if (this.value) {
        $(this).parent().addClass("is-filled");
    } else {
        $(this).parent().removeClass("is-filled");
    }
});

// ===== Modal send success  ====  
$(document).ready(function () {
    var location = window.location.href;
    if (location.indexOf("sendsuccess") > -1) {
        $('#successModal').modal('show')
    }
});